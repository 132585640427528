import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';


import { SideIcons } from '../../utils/navMenu';

import { Link } from '../../utils/general';

import { useMedia } from 'react-use';

const HeaderSimple = ({ bgColor = 'bg-white' }) => {
  const [showMenu, setShowMenu] = useState('hidden');
  const Container = styled.div`
    ${tw`flex items-center justify-between py-6 md:space-x-10`}
    ${bgColor == 'bg-white' && tw`border-b-2 border-gray-100`}
  `;
  const isMd = useMedia('(min-width: 1240px)');

  const StyledDiv = styled.div`

`

  return (
    <div tw="relative  z-50" className={bgColor}>
      <div tw="max-w-7xl mx-auto px-4 sm:px-6">
        <Container>
          <div tw="flex justify-center flex-1">
            <Link to="/">
              <img tw="h-12 w-auto" src="https://cdn.shopify.com/s/files/1/0508/0625/9870/files/ProLon_Logo_-_Large_ltgreen_256x256_49d35963-d684-49a0-84bc-8124bcd3308b_190x.png?v=1604100547" alt="" />
            </Link>
          </div>
          <div tw="-mr-2 -my-2 md:hidden flex flex-row">
            {/* <button 
              onClick={() => {
                setShowMenu('visible');
              }}
              type="button"
              tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span tw="sr-only">Open menu</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button> */}
            {SideIcons.map((e, i) => (
              e.is_main ? <a
                href={e.url}
                key={i}
                tw="text-lg lg:text-sm lg:mx-3
                    font-semibold tracking-wide transition duration-300 text-primary-900
                    pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                onClick={e.onClick}
              >
                {e.text}
              </a> : null
            ))}
          </div>
          <nav tw="hidden md:flex absolute md:right-0 px-10">

            {SideIcons.map((e) => (
              <a
                href={e.url}
                tw="text-lg lg:text-sm lg:mx-3
                font-semibold tracking-wide transition duration-300 text-primary-900
                pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline flex justify-center items-center"
                onClick={e.onClick}
              >
                {e.text}
              </a>
            ))}
          </nav>
        </Container>
      </div>

      <div
        tw="absolute top-0 inset-x-0 p-2 transition transform origin-top-right "
         style={{display: 'none'}}
        className={showMenu}
      > 
        <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-100">
          <div tw="pt-5 pb-6 px-5">
            <div tw="flex items-center justify-between">
              <div>
                <img
                  tw="h-8 w-auto"
                  src="https://cdn.shopify.com/s/files/1/0508/0625/9870/files/ProLon_Logo_-_Large_ltgreen_256x256_49d35963-d684-49a0-84bc-8124bcd3308b_190x.png?v=1604100547"
                  alt="Workflow"
                />
              </div>
              <div tw="-mr-2">
                <button
                  onClick={() => {
                    setShowMenu('hidden');
                  }}
                  type="button"
                  tw="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span tw="sr-only">Close menu</span>
                  <svg
                    tw="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div tw="flex  flex-col mt-6">
              {/* {navLinks.map((e) => (
                <Link
                  to={e.url}
                  tw="text-sm my-2 lg:text-sm lg:mx-3 lg:my-0
                font-semibold tracking-wide transition duration-300 text-primary-900
                pb-1 border-b-0 border-transparent hover:border-primary-500 hocus:text-primary-900 hover:no-underline"
                >
                  {e.text}
                </Link>
              ))} */}
            </div>
          </div>
          <div tw="py-6 px-5 space-y-6">
            <div>
              <a
                href="#"
                tw="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:text-white bg-primary-900 hover:bg-primary-900 hover:no-underline"
              >
                Sign up
              </a>
              <p tw="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a
                  href="#"
                  tw="text-primary-900 hover:text-primary-900 hover:no-underline"
                >
                  Sign in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSimple;
